'use client'

import { colorsTuple, createTheme, type CSSVariablesResolver, virtualColor } from '@mantine/core'
import { SUSE } from 'next/font/google'

const suse = SUSE({ subsets: ['latin'] })

export const theme = createTheme({
	defaultRadius: 'md',
	autoContrast: true,
	luminanceThreshold: 0.4,
	fontFamily: suse.style.fontFamily,
	headings: { fontFamily: suse.style.fontFamily, fontWeight: '500' },
	primaryColor: 'teal',
	fontSizes: {
		xxs: '0.6875rem',
		xs: '0.75rem',
	},
	spacing: {
		xl: '2rem', //32px
		lg: '1.5rem', //24px
		md: '1.25rem', //20px
		sm: '1rem', //16px
		xs: '0.75rem', //12px
		xxs: '0.5rem', //8px
		xxxs: '0.25rem', //4px
	},
	radius: {
		xl: '0.75rem', // 12px
		lg: '0.5rem', // 8px
		md: '0.375rem', // 6px
		sm: '0.25rem', // 4px
		xs: '0.125rem', // 2px
	},
	colors: {
		//Background colors
		bodyLight: colorsTuple('#fff'), // --mantine-color-body
		bodyDark: colorsTuple('#242424'), // --mantine-color-dark-7
		body: virtualColor({
			name: 'body',
			light: 'bodyLight',
			dark: 'bodyDark',
		}),
		bodyShadedLight: colorsTuple('#f3f4f4'),
		bodyShadedDark: colorsTuple('#2e2f2f'),
		bodyShaded: virtualColor({
			name: 'bodyShaded',
			light: 'bodyShadedLight',
			dark: 'bodyShadedDark',
		}),
		glassLight: colorsTuple('#ffffffb3'),
		glassDark: colorsTuple('#242424b3'),
		glass: virtualColor({
			name: 'glass',
			light: 'glassLight',
			dark: 'glassDark',
		}),
		glassShadedLight: colorsTuple('#f3f4f4b3'),
		glassShadedDark: colorsTuple('#2e2f2fb3'),
		glassShaded: virtualColor({
			name: 'glassShaded',
			light: 'glassShadedLight',
			dark: 'glassShadedDark',
		}),

		//Text Colors
		textAccentLight: colorsTuple('#12B886'), //A dark text that shows high contrast in both light and dark mode
		textAccentDark: colorsTuple('#099268'),
		textAccent: virtualColor({
			name: 'textAccent',
			light: 'textAccentLight',
			dark: 'textAccentDark',
		}),

		textBrightLight: colorsTuple('#141414'), //A dark text that shows high contrast in both light and dark mode
		textBrightDark: colorsTuple('#ffffff'),
		textBright: virtualColor({
			name: 'textBright',
			light: 'textBrightLight',
			dark: 'textBrightDark',
		}),
		textLight: colorsTuple('#141414'), //Standard Text Color
		textDark: colorsTuple('#C9C9C9'),
		text: virtualColor({
			name: 'text',
			light: 'textLight',
			dark: 'textDark',
		}),
		textDimmedLight: colorsTuple('#14141499'), //Subdued Text Color
		textDimmedDark: colorsTuple('#C9C9C999'),
		textDimmed: virtualColor({
			name: 'textDimmed',
			light: 'textDimmedLight',
			dark: 'textDimmedDark',
		}),

		//Icon Colors
		iconLight: colorsTuple('#141414'), //Standard Icon Color
		iconDark: colorsTuple('#C9C9C9'),
		icon: virtualColor({
			name: 'icon',
			light: 'iconLight',
			dark: 'iconDark',
		}),
		iconColorLight: colorsTuple('#12B886'), //based
		iconColorDark: colorsTuple('#099268'),
		iconPrimary: virtualColor({
			name: 'iconColor',
			light: 'iconColorLight',
			dark: 'iconColorDark',
		}),
		iconDimmedLight: colorsTuple('#14141499'), //Subdued Icon Color
		iconDimmedDark: colorsTuple('#C9C9C999'),
		iconDimmed: virtualColor({
			name: 'iconDimmed',
			light: 'iconDimmedLight',
			dark: 'iconDimmedDark',
		}),

		//Dividers
		dividerDimmedLight: colorsTuple('#868e9626'),
		dividerDimmedDark: colorsTuple('#868e964d'),
		dividerDimmed: virtualColor({
			name: 'dividerDimmed',
			light: 'dividerDimmedLight',
			dark: 'dividerDimmedDark',
		}),
		dividerLight: colorsTuple('#2e2e2e1a'),
		dividerDark: colorsTuple('#868e9666'),
		divider: virtualColor({
			name: 'divider',
			light: 'dividerLight',
			dark: 'dividerDark',
		}),
		dividerColorLight: colorsTuple('#12B886'),
		dividerColorDark: colorsTuple('#099268'),
		dividerColor: virtualColor({
			name: 'dividerColor',
			light: 'dividerColorLight',
			dark: 'dividerColorDark',
		}),

		//StatusColors
		statusSuccessLight: colorsTuple('#40C057'),
		statusSuccessDark: colorsTuple('#2F9E44'),
		statusSuccess: virtualColor({
			name: 'success',
			light: 'successLight',
			dark: 'successDark',
		}),
		statusErrorLight: colorsTuple('#FA5252'),
		statusErrorDark: colorsTuple('#E03131'),
		statusError: virtualColor({
			name: 'error',
			light: 'errorLight',
			dark: 'errorDark',
		}),
		statusWarningLight: colorsTuple('#F08C00'),
		statusWarningDark: colorsTuple('#E67700'),
		statusWarning: virtualColor({
			name: 'warning',
			light: 'warningLight',
			dark: 'warningDark',
		}),
		statusInfoLight: colorsTuple('#228BE6'),
		statusInfoDark: colorsTuple('#1971C2'),
		statusInfo: virtualColor({
			name: 'info',
			light: 'infoLight',
			dark: 'infoDark',
		}),
		statusWaitLight: colorsTuple('#7950F2'),
		statusWaitDark: colorsTuple('#6741D9'),
		statusWait: virtualColor({
			name: 'wait',
			light: 'waitLight',
			dark: 'waitDark',
		}),
	},
	other: {
		zIndex: {
			app: 100,
			header: 200,
			sidebar: 300,
			drawer: 400,
			overlay: 500,
			modal: 600,
			popover: 700,
			notification: 800,
			spotlight: 900,
			tooltip: 1000,
		},
	},
	components: {
		Tooltip: {
			defaultProps: {
				zIndex: 'var(--z-index-tooltip)',
			},
		},
	},
})

export const cssVariablesResolver: CSSVariablesResolver = (theme) => ({
	variables: {
		'--checkbox-radius': 'var(--mantine-radius-sm)',
		'--z-index-app': theme.other.zIndex.app,
		'--z-index-header': theme.other.zIndex.header,
		'--z-index-sidebar': theme.other.zIndex.sidebar,
		'--z-index-drawer': theme.other.zIndex.drawer,
		'--z-index-overlay': theme.other.zIndex.overlay,
		'--z-index-modal': theme.other.zIndex.modal,
		'--z-index-popover': theme.other.zIndex.popover,
		'--z-index-notification': theme.other.zIndex.notification,
		'--z-index-spotlight': theme.other.zIndex.spotlight,
		'--z-index-tooltip': theme.other.zIndex.tooltip,
	},
	light: {
		'--body': theme.colors.bodyLight[0],
		'--body-shaded': theme.colors.bodyShadedLight[0],
		'--glass': theme.colors.glassShadedLight[0],
		'--glass-shaded': theme.colors.glassShadedLight[0],

		'--text-accent': theme.colors.textAccentLight[0],
		'--text-bright': theme.colors.textBrightLight[0],
		'--text': theme.colors.textLight[0],
		'--text-dimmed': theme.colors.textDimmedLight[0],

		'--icon': theme.colors.iconLight[0],
		'--icon-color': theme.colors.iconColorLight[0],
		'--icon-dimmed': theme.colors.iconDimmedLight[0],

		'--divider': theme.colors.dividerLight[0],
		'--divider-dimmed': theme.colors.dividerDimmedLight[0],
		'--divider-color': theme.colors.dividerColorLight[0],

		'--status-success': theme.colors.statusSuccessLight[0],
		'--status-error': theme.colors.statusErrorLight[0],
		'--status-warning': theme.colors.statusWarningLight[0],
		'--status-info': theme.colors.statusInfoLight[0],
		'--status-wait': theme.colors.statusWaitLight[0],
	},
	dark: {
		'--body': theme.colors.bodyDark[0],
		'--body-shaded': theme.colors.bodyShadedDark[0],
		'--glass': theme.colors.glassShadedDark[0],
		'--glass-shaded': theme.colors.glassShadedDark[0],

		'--text-accent': theme.colors.textAccentDark[0],
		'--text-bright': theme.colors.textBrightDark[0],
		'--text': theme.colors.textDark[0],
		'--text-dimmed': theme.colors.textDimmedDark[0],

		'--icon': theme.colors.iconDark[0],
		'--icon-color': theme.colors.iconColorDark[0],
		'--icon-dimmed': theme.colors.iconDimmedDark[0],

		'--divider': theme.colors.dividerDark[0],
		'--divider-dimmed': theme.colors.dividerDark[0],
		'--divider-color': theme.colors.dividerColorDark[0],

		'--status-success': theme.colors.statusSuccessDark[0],
		'--status-error': theme.colors.statusErrorDark[0],
		'--status-warning': theme.colors.statusWarningDark[0],
		'--status-info': theme.colors.statusInfoDark[0],
		'--status-wait': theme.colors.statusWaitDark[0],
	},
})
